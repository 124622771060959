import { PageProps } from 'gatsby';
import { OrderData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import commande from '~/params/order.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PageBonsCommandeCreate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId },
}) => {
  const model = new OrderData({
    espaceId,
    params: commande,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={model.getNewDocId()}
          itemPathnamePrefix={`/espaces/${espaceId}/commandes/`}
          model={model}
          name="item"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageBonsCommandeCreate);
